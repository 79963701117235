//时间戳转为时间
export function unixtoTime (ns, format = '') {
  var result;
  var time = new Date(ns * 1000);
  result = format || 'YYYY年MM月DD日 HH时II分SS秒';
  result = result.toUpperCase();
  result = result.replace('YYYY', time.getFullYear());
  var m = time.getMonth() + 1;
  result = result.replace('MM', m > 9 ? m : "0" + m);
  var d = time.getDate();
  result = result.replace('DD', d > 9 ? d : '0' + d);
  var hh = time.getHours();
  result = result.replace('HH', hh > 9 ? hh : '0' + hh);
  var ii = time.getMinutes();
  result = result.replace('II', ii > 9 ? ii : '0' + ii);
  var ss = time.getSeconds();
  result = result.replace('SS', ss > 9 ? ss : '0' + ss);
  var week;
  switch (time.getDay()) {
    case 0:
      week = "日";
      break
    case 1:
      week = "一";
      break
    case 2:
      week = "二";
      break
    case 3:
      week = "三";
      break
    case 4:
      week = "四";
      break
    case 5:
      week = "五";
      break
    case 6:
      week = "六";
      break
  }
  result = result.replace('WW', week);
  if (format.indexOf('DAY') > 0) {
    // 获取当天 0 点的时间戳
    var timeStamp = new Date(new Date().setHours(0, 0, 0, 0)) / 1000;
    var day1 = timeStamp + 86400//今天结束
    var day2 = day1 + 86400//明天结束
    if (ns >= timeStamp && ns < day1) {
      result = result.replace('DAY', '今天');
    } else {
      if (ns >= day1 && ns < day2) {
        result = result.replace('DAY', '明天');
      } else {
        result = result.replace('DAY', '');
      }
    }
  }
  if (format.indexOf('`') > -1) {
    result = result.replace(/`(.*)\/(.*)`/ig, function ($0, $1, $2) {
      return $1 || $2;
    });
  }
  return result;
}
export function getDiffTime (new_date, old_date) {
  var subtime = (new_date - old_date);    //计算时间差,并将毫秒转化为秒
  var days = parseInt(subtime / 86400);  //天  24*60*60*1000
  var hours = parseInt(subtime / 3600) - 24 * days;   //小时  60*60  总小时数-过去小时数=现在小时数
  var mins = parseInt(subtime % 3600 / 60);    //分钟 - (day*24)  以60秒为一整份  取余 剩下秒数 秒数/60就是分钟数
  var secs = parseInt(subtime % 60);   //以60秒为一整份  取余  剩下秒数
  return [days, hours, mins, secs];
}
// 判断是json   进行parse转化
export function determine (str) {
  if (typeof str == 'string') {
    try {
      var obj = JSON.parse(str);
      return obj;
    } catch (e) {
      return str;
    }
  } else {
    return str
  }
}