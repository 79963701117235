import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/font_3639320_00p2k5rtprzn/iconfont.css'
import './util/flexible'
import '../src/assets/style.css'
import axios from 'axios'
Vue.config.productionTip = false
// 创建全局EventBus
Vue.prototype.$EventBus = new Vue()
Vue.prototype.$axios = axios
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
//按需引入组件
import { Switch,Autocomplete, DatePicker, Loading, Pagination, Dialog, Message, MessageBox, Input, Table, Aside, Checkbox, Header, Main, Calendar, Container, TableColumn, InputNumber, Select, Option, Tag, Form, FormItem, Tooltip, Button, Toast } from 'element-ui'
//注册引入的组件
Vue.use(Switch),
Vue.use(Autocomplete),
Vue.use(DatePicker),
Vue.use(Pagination),
Vue.use(Input),
Vue.use(Checkbox),
Vue.use(Header),
Vue.use(Main),
Vue.use(Calendar),
Vue.use(Container),
Vue.use(Aside);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(InputNumber);
Vue.use(Select);
Vue.use(Option);
Vue.use(Tag);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tooltip);
Vue.use(Button);
Vue.use(Dialog);
Vue.use(Loading.directive);

Vue.prototype.$roles = {
  cancel: ['管理员', '店长', '前台'],//取消预订
  edit: ['管理员', '店长', '前台'],//变更预订
  sending: ['管理员', '店长', '前台'],//发送短信
  arrival: ['管理员', '店长', '前台', '迎宾'],//到店
  leave: ['管理员', '店长', '前台', '迎宾'],//离店
  addBanquet: ['管理员', '店长', '客户经理', '收银员', '服务员', '前台'],//创建宴会
  addBook: ['管理员', '店长', '客户经理', '收银员', '服务员', '前台'],//新建预订
  depositNuclearMeal: ['管理员', '店长', '客户经理', '收银员', '服务员', '前台'],//核餐和订金
};
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$message = Message;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
