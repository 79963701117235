<template>
  <div id="app">
    <div id="phoneFun" @click="getEventFun"></div>
    <router-view />
    <div v-show="mask" class="popContainer"></div>
    <el-dialog
      class="phoneDialog"
      :title="`电话线路${circuit}  来电提醒 ${currentTime}`"
      :visible.sync="dialogVisible"
      width="40%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="dialogContent">
        <img
          class="topImg"
          src="https://qn.colorcun.com/close.png"
          @click="closeDialog"
        />
        <div class="topBox">
          <div class="infoLeft">
            <div class="phone">
              <img src="https://qn.colorcun.com/phone.png" class="phoneImg" />
              <div class="texts">
                {{ incomingCall.str }}
                {{
                  (eatList.length && eatList[0].guestname && eatList[0].sexStr) ? eatList[0].guestname + eatList[0].sexStr : ''
                }}
              </div>
            </div>
            <div class="frequency">
              <div class="text">预订次数</div>
              <div class="line"></div>
              <div class="text">
                {{ eatList.length ? eatList.length : '未知' }}
              </div>
            </div>
          </div>
          <div class="rightBox">
            <div class="info">
              忌口：{{
                eatList.length && eatList[0].taboos ? eatList[0].taboos : ''
              }}
            </div>
            <div class="info">
              喜好：{{
                eatList.length && eatList[0].like ? eatList[0].like : ''
              }}
            </div>
            <div class="info">
              服务：{{
                eatList.length && eatList[0].serve ? eatList[0].serve : ''
              }}
            </div>
            <div class="info">
              标签：{{
                eatList.length && eatList[0].label && eatList[0].label!='0'? eatList[0].label : ''
              }}
            </div>
          </div>
        </div>
        <div class="boxInfo">
          <div class="details" v-for="item in eatList" :key="item.id">
            <div class="text text1">
              {{ item.rdate }}
              {{ item.ddate_solt_str }}
              <div class="line" v-if="item.tableinfo_1"></div>
              {{ item.tableinfo_1 }}
            </div>
            <div class="itemBox">
              <div class="itemLeft">
                <div class="text" v-if="item.actual_pum">
                  就餐人数：{{
                    item.actual_pum && item.actual_pum != 0
                      ? item.actual_pum + '位'
                      : ''
                  }}
                </div>
                <div class="text">到店时间：{{ item.ddate }}</div>
                <div class="text">
                  专属经理：{{ item.seller_user_nickname }}
                  {{ item.seller_user_phone ? item.seller_user_phone : '' }}
                </div>
                <div class="text">备注：{{ item.cusdem }}</div>
                <div class="text">下单时间：{{ item.cdate }}</div>
                <div class="text">
                  操作人：{{ item.note_name != null ? item.note_name : '' }}
                </div>
              </div>
              <div
                class="rightBtns"
                v-if="
                  item.status != '4' && item.status != '3' && item.status != '0' && item.status != '6'
                "
              >
                <button
                  :disabled="item.sendingRole"
                  @click="sendSmsFunc(item)"
                  :class="item.sendingRole ? 'sendDisabledBtn' : 'send'"
                  >发送短信</button
                >
                <div class="book">
                  <button
                    :disabled="item.cancelRole"
                    :class="{ disabledBtn: item.cancelRole }"
                    @click="cancelReserve(item)"
                    >取消预订</button
                  >
                  <button
                    :disabled="item.editRole"
                    :class="{ disabledBtn: item.editRole }"
                    @click="changeClick(item)"
                    >变更预订</button
                  >
                </div>
              </div>
              <div class="rightBtns" v-if="item.status == '4'">
                <button plain round size="medium" class="btnsDid" disabled
                  >已取消</button
                >
              </div>
              <div class="rightBtns" v-if="item.status == '0'">
                <button plain round size="medium" class="btnsDid" disabled
                  >未处理</button
                >
              </div>
              <div class="rightBtns" v-if="item.status == '6'">
                <button plain round size="medium" class="btnsDid" disabled
                  >已拒单</button
                >
              </div>
              <div class="rightBtns" v-if="item.status == '3'">
                <button plain round size="medium" class="btnsDid" disabled
                  >已离店</button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <button
          :disabled="addBookRole"
          :class="{ btnBookRole: true, disabledBtn: addBookRole }"
          @click="addReservation"
          >新建预订</button
        >
      </span>
    </el-dialog>
    <div class="residueDialog">
      <el-dialog
        title="温馨提示"
        :visible.sync="residueDialogVisible"
        width="30%"
        :modal-append-to-body="true"
        :show-close="false"
        center
        :before-close="handleClose"
      >
        <div v-if="types == '1'" class="public residueTexts tapTexts">
          您的店铺即将在
        </div>
        <div class="prompt public">
          {{
            types == '1'
              ? expireDate + ' 过期'
              : types == '2'
              ? '您得店铺已过期'
              : ''
          }}
        </div>
        <div class="public residueTexts">请联系您的服务商或拨打</div>
        <div class="public residueTexts">400-000-9690 及时续费</div>
        <div class="public residueTexts lastTexts">以免影响您的正常使用</div>
        <span slot="footer" class="dialog-footer">
          <el-button class="closeBtn" @click="handleClose">我知道了</el-button>
        </span>
      </el-dialog>
    </div>
    <div
      v-if="messageObj && messageObj.id && messageObj.ddateInfo"
      class="messageBox"
    >
      <div class="messageTop">
        <h1>
          {{ messageObj.phone }}&nbsp;&nbsp;{{ messageObj.rname
          }}{{ temp_sex[messageObj.sex * 1 - 1] }}
        </h1>
        <img
          src="https://qn.colorcun.com/close.png"
          @click="messageObj.id = null"
        />
      </div>
      <div class="messageBottom">
        <div class="messageDate">
          <p>
            {{ messageObj.ddateInfo }}&nbsp;&nbsp;{{
              messageObj.ddateSolt
            }}&nbsp;&nbsp;{{ messageObj.tablename }}
          </p>
          <p>备注：{{ messageObj.cusdem }}</p>
        </div>
        <hr />
        <div class="messageDate">
          <p>预订次数：{{ messageObj.tablename }}</p>
          <p>店内标签：{{ messageObj.ddateInfo }}</p>
        </div>
      </div>
      <div class="sure">
        <el-button class="send" @click="getInfoStatus(messageObj,'2')">
          安排包间
        </el-button>
        <el-button class="useBtn" @click="getInfoStatus(messageObj,'1')">
          狠心拒绝
        </el-button>
      </div>
    </div>
    <div class="openClose" v-if="cancelShowBox">
      <Cancel
        @su="cancelCloseClick"
        :showBox="cancelShowBox"
        :Detail="checkItem"
      >
      </Cancel>
    </div>
    <div class="openClose" v-if="noArrangeShow">
      <NoArrange @noArrangeClose="noArrangeCloseClick" :Detail="messageObj">
      </NoArrange>
    </div>
  </div>
</template>
<script>
import {
  BriEvent_PhoneHook, BriEvent_PhoneHang, BriEvent_CallIn, BriEvent_GetCallID, BriEvent_StopCallIn,
  BriEvent_DialEnd, BriEvent_SendCallIDEnd, BriEvent_RingTimeOut, BriEvent_Ringing, BriEvent_Silence,
  BriEvent_GetDTMFChar, BriEvent_RemoteHook, BriEvent_RemoteHang, BriEvent_Busy, BriEvent_DialTone, BriEvent_PhoneDial,
  BriEvent_RingBack, BriEvent_MicIn, BriEvent_MicOut, BriEvent_FlashEnd, BriEvent_RefuseEnd, BriEvent_SpeechResult,
  BriEvent_OpenSoundFailed, BriEvent_UploadSuccess, BriEvent_UploadFailed, BriEvent_EnableHook, BriEvent_EnablePlay, BriEvent_EnableMic,
  BriEvent_EnableSpk, BriEvent_EnableRing, BriEvent_DoRecSource, BriEvent_DoStartDial,
  BriEvent_RecvedFSK, BriEvent_DevErr, BriEvent_PlugOut, BriEvent_EndID
} from "./assets/js/deviceapi";
import $ from 'jquery'
import Cancel from "./components/Cancel.vue";
import NoArrange from "./components/NoArrange.vue";
import eventBus from "./util/eventBus";
import Vue from 'vue'
import {
  GetReservePcByPhone,
  sendSms,
  order_detail,
} from "./api/api";
import { getDiffTime, unixtoTime } from '../src/assets/js/utils'
import { SavePcReserveMessage, GetSellerVersion,GetDzSellerConf,CloseMessage,manager_list  } from "@/api/api"
import { mapState } from 'vuex';
export default {
  data () {
    return {
      addBookRole: true,//新建预定权限
      expireDate: '',
      types: '',//1临期2过期
      day: '',//剩余天数 7 3 2 1
      residueDialogVisible: false,
      dialogVisible: false,
      cancelShowBox: false,
      noArrangeShow: false,
      checkItem: {},
      num: 1,
      num1: 1,
      incomingCall: {
        phone: '',
        str: ''
      },
      eatList: [],
      temp_sex: ["先生", "女士", "公司"],
      date: Date.parse(new Date()) * 1 / 1000,
      timer: null,
      circuit: 0,//来电线路
      currentTime: '',//来电时间
      mask: false,
      currentPhone:'',//当前弹框来电号码
      phoneline1:'',//线路1来电或者拨号获取到的号码（线路1）
      phoneline2:'',//线路2来电或者拨号获取到的号码（线路2）
      phoneEnd1:'',//只有来电需要设置，用于挂机短信（线路1）
      phoneEnd2:'',//只有来电需要设置，用于挂机短信（线路2）
    }
  },
  components: {
    Cancel,
    NoArrange
  },
  computed: {
    ...mapState({
      messageObj: state => state.websocket.messageObj,
      callPhone: state => state.callPhone,
      openRecordObj: state => state.openRecordObj,//录音配置
    })
  },
  created () {
    localStorage.removeItem('phoneLine')
    this.showDialogFun()
    console.log('APP created');
    eventBus.$on("openIncoming",(flag)=>{
      this.dialogVisible=flag
      this.incomingCall.str=this.callPhone.phone
      this.circuit=this.callPhone.num
      this.currentTime=this.callPhone.time
      localStorage.setItem('phoneLine',1)
      this.getCustomerInfo(this.callPhone.phone)
    })
    let sellerid = localStorage.getItem("sellerid")
    this.$store.commit('getCallPhone',{})
    // 开启连接ws
    if (sellerid) {
      localStorage.removeItem('workerID')
      localStorage.removeItem('connectionID')
      this.$store.dispatch('onopenWebSocket', {
        sellerid: localStorage.getItem("sellerid"),
        account: localStorage.getItem("account"),
        uid: localStorage.getItem("uid"),
      })
      this.$store.commit('getInfo', {})
      this.getDzSellerConfFun(sellerid)
    }
  },
  methods: {
    getEventFun(){
      this.getEvents(this.T_GetEvent());
    },
    // 获取录音设置,保存在本地
    getDzSellerConfFun(sid){
      GetDzSellerConf({
          service: "App.Seller.GetDzSellerConf",
          sid,
      }).then(res=>{
        let openRecord='0'
        if(res.data.data && res.data.data.is_open_record=='1'){
          openRecord='1'
        }else{
          openRecord='0'
        }
        this.$store.commit('getOpenRecordObj',{
          openRecord,
          listings:res.data.data.record_path || 'c'
        })
      })
    },
    // 判断当前预订，顾客是否取消，没有取消，在进行安排包间或者拒绝安排操作
    getInfoStatus(item,type){
      order_detail({
        service: "Android.Reserve.Detail",
        rid: this.messageObj.id,
      }).then((res) => {
        if (res.data.ret == 200) {
          let status=res.data.data.status;
          let msg='';
          if(status=='2'){
            msg='当前预订已到店，无法操作！'
          }
          if(status=='3'){
            msg='当前预订已离店，无法操作！'
          }
          if(status=='4'){
            msg='当前预订已取消，无法操作！'
          }
          if(status=='5' || status=='1'){
            msg='当前预订已安排，无法操作！'
          }
          if(status=='6'){
            msg='当前预订已拒单，无法操作！'
          }
          if(msg){
            this.$store.commit('setmessageObj');
            this.$store.commit("getLengthFun")
            return this.$message({
              message: msg,
              type: 'warning'
            });
          }
          if(type=='1') this.refuseArrange()
          if(type=='2') this.arrangeRoom(item)
        }
      });
    },
    // 安排包间
    arrangeRoom (item) {
      let obj = {
        phone: item.phone,
        rname: item.rname,
        sex: item.sex,
        ddateSolt: item.ddateSolt,
        remarkValue: item.cusdem,
        ddate: item.ddate * 1,
        contentId: item.id,
        messageId: item.id,
        pnum: item.pnum,
        type: '1',//消息提醒1   来电预订2
      }
      console.log('跳转到新增页传参', { ...obj });
      this.$store.commit('setmessageObj');//关闭消息提醒弹框
      this.$store.commit('getInfo', obj);
      this.$store.commit("getLengthFun")
      if (this.$route.path == '/' || this.$route.path == '/home') {
        return false
      }
      this.$router.push("/home");
    },
    // 拒绝安排包间
    refuseArrange () {
      this.mask = true;
      this.noArrangeShow = true
    },
    showDialogFun () {
      let sellerid = localStorage.getItem("sellerid")
      if (sellerid) {
        // 获取商家的版本信息
        GetSellerVersion({
          service: "App.SellerVersion.GetSellerVersion",
          sellerid,
        }).then((ress) => {
          let data = ress.data.data;
          let dates = Date.parse(new Date()) / 1000// 现在年月日时分秒的时间戳
          if(data && data.length>0){
            data.forEach(item => {
              let dateStr = new Date(item.rdate * 1000);
              dateStr.setHours(0, 0, 0, 0); 
              item.rdateDate = dateStr.getTime() / 1000;// 启用时间的年月日加0时0分0秒
            });
            data = data.filter(item => item.rdateDate < dates);//去除还没到启用时间的数据
          }
          if (data && data.length > 0) {
            data.sort((a, b) => b.cdate*1 - a.cdate*1);
            // 获取店铺当前版本  （cdate最大最后一个充入的 && 当前时间有效 && 当前版本）
            let obj = data.find(item => item.vtype == 1 || item.vtype == 2 || item.vtype == 4);
            console.log('当前版本1',{...obj});
            let edate = obj.edate;
            localStorage.setItem("sellEdate", edate)//过期弹框
            let residue = edate - dates//剩余天数的时间戳
            let residueDate = getDiffTime(edate, dates)//剩余天数的时间戳
            this.expireDate = unixtoTime(edate, "YYYY-MM-DD 00:00")
            console.log('过期的时间戳---', edate, '过期时间---', this.expireDate, '现在年月日时分秒的时间戳---', dates, '剩余天数时间戳---', residue, '剩余天数数组---', residueDate);
            if (edate && residueDate.length == 4 && (residueDate[0] == 7 || residueDate[0] == 3 || residueDate[0] == 2 || residueDate[0] == 1)) {
              this.day = residueDate[0]
              let show = false;
              let day;
              switch (residueDate[0]) {
                case 7:
                  day = localStorage.getItem("day7")
                  show = day == '1' ? false : true
                  localStorage.setItem("day7", '1')//过期天数
                  break;
                case 3:
                  day = localStorage.getItem("day3")
                  show = day == '1' ? false : true
                  localStorage.setItem("day3", '1')//过期天数
                  break;
                case 2:
                  day = localStorage.getItem("day2")
                  show = day == '1' ? false : true
                  localStorage.setItem("day2", '1')//过期天数
                  break;
                case 1:
                  day = localStorage.getItem("day1")
                  show = day == '1' ? false : true
                  localStorage.setItem("day1", '1')//过期天数
                  break;
              }
              console.log('剩余7天、3天、2天、1天');
              this.types = '1'
              this.residueDialogVisible = show
            } else if (edate && residueDate.length == 4 && residueDate[0] == 0 && residueDate[3] > 0) {//剩余不足一天
              console.log('剩余不足一天');
              let day = localStorage.getItem("day0")
              let show = day == '1' ? false : true
              localStorage.setItem("day0", '1')//过期天数
              this.types = '1'
              this.residueDialogVisible = show
            } else if (edate && residueDate.length == 4 && residueDate[0] < 0 || (residueDate[0] == 0 && residueDate[3] <= 0)) {//已到期
              console.log('已到期');
              this.types = '2'
              this.residueDialogVisible = true
            } else if (edate && residueDate.length == 4 && residueDate[0] > 7) {//大于8天 全部置空，确保下次到期弹框
              localStorage.removeItem("day7")//过期天数
              localStorage.removeItem("day3")//过期天数
              localStorage.removeItem("day2")//过期天数
              localStorage.removeItem("day1")//过期天数
              localStorage.removeItem("day0")//过期天数
            } else if (edate && (residueDate[0] == 0 || residueDate[0] < 0)) {
              this.types = '2'
              this.residueDialogVisible = true
            }
          }
        })
      }
    },
    // 关闭弹框
    handleClose () {
      if (this.types == '1') {
        this.residueDialogVisible = false
        return false
      }
      if (this.types == '2') {
        this.residueDialogVisible = false
        if (this.$route.path == '/' || this.$route.path == '/login') {
          return false
        }
        this.$router.push('/login')
      }
    },
    cancelReserve (item) {
      this.checkItem = item;
      setTimeout(() => {
        this.cancelShowBox = true;
        eventBus.$emit("cancelShowBox", true);
        this.$nextTick(() => {
          const dialogWrapper = document.querySelector('.phoneDialog');
          if (dialogWrapper) {
            dialogWrapper.style.zIndex = 1000;
          }
        });
      }, 1);
    },
    closeDialog () {
      this.dialogVisible = false
      this.cancelShowBox = false;
      this.incomingCall.phone == ''
      eventBus.$emit("cancelShowBox", false);
    },
    addReservation () {
      this.dialogVisible = false;
      this.cancelShowBox = false;
      this.incomingCall.phone == ''
      eventBus.$emit("cancelShowBox", false);
      let one = (this.eatList.length && this.eatList[0]) ? this.eatList[0] : ''
      let obj = {
        phone: this.currentPhone,
        rname: one.guestname,
        sex: one.guestsex,
        searchNewData: this.eatList.length > 0 ? true : false,//有预订过，查询手机号和名称，没有预定过，不搜索
        type: '2',//消息提醒1   来电预订2
      }
      console.log('来电预订消息',{...obj});
      this.$store.commit('getInfo', obj);
      if (this.$route.path == '/' || this.$route.path == '/home') {
        return false
      }
      this.$router.push("/home");
    },
    changeClick (obj) {
      sessionStorage.setItem("SetReserveInfo", JSON.stringify(obj));//
      this.dialogVisible = false;
      this.incomingCall.phone == ''
      this.$store.commit('getReserveInfoObj',JSON.stringify(obj))
      if (this.$route.path != '/setreserve') {
        this.$router.push("/setreserve");
      }
    },
    // 发送挂机短信
    closeSmsFun(phone){
      CloseMessage({
        service: "App.SaiYouYun.CloseMessage",
        sid:localStorage.getItem("sellerid"),
        phone,
      }).then(res=>{
        console.log('CloseMessage结果',res);
        if(res.data.ret!=200){
          return this.$message({
            message: '挂机短信发送失败！'+res.data.msg,
            type: 'error'
          });
        }
        if(res.data.data){
          let data=JSON.parse(res.data.data)
          console.log('挂机短信请求结果',data);
          if (data.status == 'success') {
            setTimeout(() => {
              this.$message({
                message: '挂机短信发送成功',
                type: 'success'
              });
            }, 2000);
          } else {
            setTimeout(() => {
              this.$message({
                message: res.data.msg?res.data.msg:'挂机短信发送失败！',
                type: 'error'
              });
            }, 2000);
          }
        }
      })
    },
    // 关闭取消预订弹框
    cancelCloseClick (val) {
      this.cancelShowBox = val;
      if (val == false) {
        eventBus.$emit("cancelShowBox", false);
        this.getCustomerInfo(this.currentPhone)
        Vue.prototype.$ELEMENT = { size: "small", zIndex: 2000 };
        this.$nextTick(() => {
          const dialogWrapper = document.querySelector('.phoneDialog');
          if (dialogWrapper) {
            dialogWrapper.style.zIndex = 3000;
          }
        });
      }
    },
    // 关闭拒绝安排预订弹框
    noArrangeCloseClick (val) {
      this.noArrangeShow = val.noShow;//预订拒绝弹框
      eventBus.$emit("noArrangeShow", val.noShow);//预订拒绝弹框
      this.mask = val.noShow//预订拒绝弹框
      this.infoShow = val.infoShow;//预订消息弹框
      if (!val.infoShow) {
        this.$store.commit('setmessageObj');//关闭消息提醒弹框
      }
      eventBus.$emit("resetList",{
        noShow:false,//关闭遮罩层
      });//更新预定列表最新的消息
    },
    getEvents (parseEvent) {
      $.ajax({
        type: "post",
        url: "http://127.0.0.1:3001/msg/",
        dataType: "jsonp",
        timeout: 30000,
        data: { "act": "get", "timeout": 30000 },
        //data中的timeout值要跟前面AJAX的timeout值相等，不设置默认是30000
        success: ((data, textStatus) => {
          console.log('请求成功getEvents', data, textStatus);
          // $("#StatusArea").append("--- " + data );
          if (textStatus == "success") {
            this.getEvents(parseEvent);
          }
          if (data != null) {
            this.T_GetEvent(data.ch, data.e_type, data.e_handle, data.l_result, data.e_data)
            // parseEvent(data.ch,data.e_type,data.e_handle,data.l_result,data.e_data);
          }
        }),
        complete: ((XMLHttpRequest, textStatus) => {
          if (XMLHttpRequest.readyState == "4") {
            //console.log(XMLHttpRequest.responseText);  
          }
        }),
        error: ((XMLHttpRequest, textStatus, errorThrown) => {
          console.log('getEvents请求失败', textStatus);
          if (!this.dialogVisible) {
            this.incomingCall.phone == ''
          }
          if (textStatus == "timeout") { // 请求超时，递归调用
            this.getEvents(parseEvent);
          } else {// 其他错误，如网络错误等
            this.getEvents(parseEvent);
          }
        })
      });
    },
    // 根据手机号获取预订列表
    getCustomerInfo (phone) {
      this.currentPhone=phone;
      var temp_ddate_slot = ["早餐", "午餐", "晚餐"];
      let params = {
        service: "App.Reserve.GetReservePcByPhone",
        sid: localStorage.getItem("sellerid"),
        phone,
      };
      GetReservePcByPhone(params).then((res) => {
        if (res.status == 200) {
          let list = res.data.data;
          let role = localStorage.getItem('role_name');
          let uid = localStorage.getItem('uid');
          list.forEach(item => {
            item.ddate_solt_str = temp_ddate_slot[item.ddate_solt * 1 - 1];
            var tableinfo = item.tableinfo ? JSON.parse(item.tableinfo) : '';
            var temp_table = "";
            var temp_original_tid = [];
            if (tableinfo) {
              tableinfo.forEach(item1 => {
                if (item1.name) {
                  temp_table += item1.name + ",";
                  temp_original_tid.push({ 'id': item1.id, 'name': item1.name });
                }
              });
              var l = temp_table.length;
              temp_table = temp_table.slice(0, l - 1);
              item.tableinfo_1 = temp_table;
              item.original_tid = temp_original_tid;
            }
            //保留没转前的原phone数据
            item.original_phone = item.phone;
            item.phone = item.phone.substr(0, 3) +
              "*" +
              item.phone.substr(7);
            //映射预订订单状态
            //转到店时间为日期格式
            item.ddate = this.ChangeReserveTime(
              item.ddate,
              "ddate",
              1
            );
            //转到店时间为日期格式
            item.cdate = this.ChangeReserveTime(
              item.cdate,
              "cdate",
              1
            );
            //保留没转前的原rdate数据
            item.original_rdate = item.rdate;
            item.rdate = this.ChangeReserveTime(
              item.rdate,
              "rdate",
              1
            );
            //映射预订订单状态
            var tempsexStr = this.temp_sex[item.sex - 1]
            item.rnames = tempsexStr ? item.rname + tempsexStr : item.rname;
            let self = (uid == item.uid);//是否是自己下单的预订
            item.editRole = ((this.$roles.edit.indexOf(role) != -1 || self) && role != '迎宾') ? false : true;
            item.cancelRole = ((this.$roles.cancel.indexOf(role) != -1 || self) && role != '迎宾') ? false : true;
            item.sendingRole = ((this.$roles.sending.indexOf(role) != -1 || self) && role != '迎宾') ? false : true;
            item.sexStr = this.temp_sex[item.guestsex - 1];
          });
          this.eatList = list;
          this.getManagerList()
        }
      });
    },
    // 发送短信
    sendSmsFunc (item) {
      if (item.original_phone && item.original_phone.length != '11') {
        this.$message({
          message: '发送失败，手机号码不正确',
          type: 'error'
        });
        return false
      }
      if (this.timer && localStorage.getItem("phone") == item.original_phone) {
        this.$message({
          message: '2分钟内只能生效一次，请稍后再试！',
          type: 'warning'
        });
        return;
      }
      // 启动计时器，2分钟后将timer变量置为null
      this.timer = setTimeout(() => {
        this.timer = null;
      }, 120000);
      let params = {
        service: "App.SendSMS.WxSendSms",
        rid: item.id,
        sid: localStorage.getItem("sellerid"),
      };
      localStorage.setItem("phone", item.original_phone)
      sendSms(params).then((res) => {
        if (res && res.data && res.data) {
          let data = res.data
          if (data.data && data.data.status && (data.data.status == '2' || data.data.status == '4')) {
            this.$message({
              message: data.data.status == '2' ? '订单已到店' : '订单已取消',
              type: 'warning'
            });
            this.timer = null;
          } else if (data.data && this.isValidJson(data.data) && this.isValidJson(data.data).status == 'success') {
            this.$message({
              message: '发送成功',
              type: 'success'
            });
          } else {
            this.$message({
              message: '发送失败！',
              type: 'error'
            });
            this.timer = null;
          }
        }
      });
    },
    isValidJson (value) {
      try {
        JSON.parse(value);
        return JSON.parse(value);
      } catch (error) {
        return false;
      }
    },
    //转订单中时间戳为日期格式
    ChangeReserveTime (time, type, flag) {
      const temp_ddate = new Date(time * 1000);
      if (flag == 1) {
        var hour = temp_ddate.getHours();
        var minutes = temp_ddate.getMinutes();
        var seconds = temp_ddate.getSeconds();
        if (hour >= 0 && hour <= 9) {
          hour = "0" + hour;
        }
        if (minutes >= 0 && minutes <= 9) {
          minutes = "0" + minutes;
        }
        if (seconds >= 0 && seconds <= 9) {
          seconds = "0" + seconds;
        }
      }
      let temp_ddateStr =
        temp_ddate.getFullYear() +
        "-" +
        (temp_ddate.getMonth() + 1) +
        "-" +
        temp_ddate.getDate();
      if (flag == 1) {
        temp_ddateStr += " " + hour + ":" + minutes + ":" + seconds;
      }
      if (type == "ddate") {
        return hour + ":" + minutes;
      } else if (type == "cdate") {
        return temp_ddateStr;
      } else if (type == "rdate") {
        return temp_ddate.getFullYear() + "年" + (temp_ddate.getMonth() + 1) + "月" + temp_ddate.getDate() + "日";
      }
      //转到店时间为日期格式
    },
    T_GetEvent (uID, uEventType, uHandle, uResult, szdata) {
      let sellerid = localStorage.getItem('sellerid')
      if (uEventType == -1) return;
      var vValue = " type=" + uEventType + " Handle=" + uHandle + " Result=" + uResult + " szdata=" + szdata;
      let obj = {
        sellerid,
        uID,
        uEventType,
        uHandle,
        uResult,
        szdata,
        vValue,
        BriEvent_GetCallID,
      }
      console.log('进入T_GetEvent方法', { ...obj });
      switch (uEventType) {
        case BriEvent_PhoneHook:// 本地电话机摘机事件
          console.log(uID, "本地电话机摘机" + vValue);
          let uIDLine=uID+1;
          let openRecord=this.openRecordObj.openRecord;
          console.log('是否录音',{...this.openRecordObj});
          // 录音，传参线路对应的手机号
          if(uIDLine==1 && openRecord=='1'){
            TV_StartRecordFile(uID,this.phoneline1,this.openRecordObj.listings)
          }
          if(uIDLine==2 && openRecord=='1'){
            TV_StartRecordFile(uID,this.phoneline2,this.openRecordObj.listings)
          }
          
          break;
        case BriEvent_PhoneDial:// 只有在本地话机摘机，没有调用软摘机时，检测到DTMF拨号
          console.log(uID, "本地话机拨号" + vValue);
          if (szdata.trim().length == '12' || szdata.trim().length == '11') {
            let lines = uID + 1
            let phone = szdata.trim().length == '12' ? szdata.trim().substring(1) : szdata.trim();
            if(lines=='1'){//保存线路对应的手机号，录音需要用
              this.phoneline1=phone
              this.phoneEnd1='';//因为是拨号，所以需要设置号码为空（该字段用于挂机短信）
              localStorage.setItem('phoneline1',phone)
            }
            if(lines=='2'){//保存线路对应的手机号，录音需要用
              this.phoneline2=phone
              this.phoneEnd2='';//因为是拨号，所以需要设置号码为空（该字段用于挂机短信）
              localStorage.setItem('phoneline2',phone)
            }
          }
          break;
        case BriEvent_PhoneHang:// 本地电话机挂机事件
          TV_HangUpCtrl(uID);
          console.log(uID, "本地电话机挂机" + vValue);
          if (szdata.length == '12') {
            console.log('获取到了本地电话机挂机事件---------------');
          }
          if (!this.dialogVisible) {
            this.incomingCall.phone == ''
          }
          TV_StopRecordFile(0)
          this.endCallFun(uID + 1)
          this.determineSending(uID)// 来电结束，判断是否发送挂机短信
          break;
        case BriEvent_CallIn:// 外线通道来电响铃事件
          console.log(uID, '外线通道来电响铃事件szdata=', szdata, 'uID=', uID, 'vValue=', vValue,  'phone=', this.incomingCall.phone);
          let phoneUid = localStorage.getItem('phoneLine');
          console.log('外线通道来电phoneUid',phoneUid);
          // !phoneUid 没有已来电信息的情况下 才能弹框
          if (!phoneUid && sellerid && (szdata.length != '12' || szdata.length != '11') && this.incomingCall.phone == '') {
            this.circuit = uID + 1;
            this.currentTime = this.getTime();
            this.incomingCall.phone = '';
            this.incomingCall.str = '正在获取来电号码……';
            this.dialogVisible = true;
            this.eatList = [];
          }
          break;
        case BriEvent_GetCallID://得到来电号码
          console.log(uID, '得到来电号码事件szdata=', szdata, 'uID=', uID, 'vValue=', vValue);
          let num1 = uID + 1
          szdata = szdata.trim();
           // 去掉前缀86、+86、00
          if (szdata.startsWith('86') || szdata.startsWith('+86') || szdata.startsWith('00')) {
            szdata = szdata.substring(2);
          }
          let phoneUid1 = localStorage.getItem('phoneLine');
          console.log('得到来电号码事件phoneUid',phoneUid1);
          if (sellerid && (szdata.length == '12' || szdata.length == '11')) {
            console.log('满足条件1');
            let time = this.getTime()
            let phone = szdata.length == '12' ? szdata.substring(1) : szdata;
            if(num1=='1'){//保存线路对应的手机号，录音需要用
              this.phoneline1=phone;//来电拨号都需要设置
              this.phoneEnd1=phone;//只有来电需要设置，用于挂机短信
              localStorage.setItem('phoneline1',phone)
            }
            if(num1=='2'){//保存线路对应的手机号，录音需要用
              this.phoneline2=phone;//来电拨号都需要设置
              this.phoneEnd2=phone;//只有来电需要设置，用于挂机短信
              localStorage.setItem('phoneline2',phone)
            }
            //第一个来电未结束，第二个来电的情况下(把第二个来电信息展示在home 桌位列表下面)
            if (phoneUid1) {
              console.log('第一个来电未结束，第二个来电的情况下');
              this.$store.commit('getCallPhone',{
                num:uID + 1,
                time,
                phone
              })
            } else {
              // 没有来电的情况下 才能弹框
              console.log('得到来电号码事件2222', phone);
              this.circuit = uID + 1;
              this.incomingCall.phone = phone;
              this.incomingCall.str = phone;
              this.dialogVisible = true;
              this.currentTime = time;
              localStorage.setItem('phoneLine', '1');//第一个来电开启
              this.getCustomerInfo(phone)
            }
            // 不管是是不是冲突的来电，都需要保存在pc消息里
            this.recordCall({
              currentTime: time,//当前时间戳
              phone: phone,//来电号码
              circuit: num1,//线路
            })
          }
          break;
        case BriEvent_StopCallIn:// 对方停止呼叫(产生一个未接电话)
          console.log(uID, "对方停止呼叫(产生一个未接电话)" + vValue + 'bhrfgfhthtgffffffffffffffffffffffffffffffffffffffffff');
          if (!this.dialogVisible) {
            this.incomingCall.phone == ''
          }
          this.endCallFun(uID + 1)
          this.determineSending(uID)// 来电结束，判断是否发送挂机短信
          break;
        case BriEvent_DialEnd:// 调用开始拨号后，全部号码拨号结束
          console.log(uID, "调用开始拨号后，全部号码拨号结束" + vValue);
          break;
        case BriEvent_SendCallIDEnd:// 给本地设备发送震铃信号时发送号码结束
          console.log(uID, "给本地设备发送震铃信号时发送号码结束" + vValue);
          break;
        case BriEvent_RingTimeOut://给本地设备发送震铃信号时超时
          console.log(uID, "给本地设备发送震铃信号时超时" + vValue);
          break;
        case BriEvent_Ringing://正在内线震铃
          console.log(uID, "正在内线震铃" + vValue);
          break;
        case BriEvent_Silence:// 通话时检测到一定时间的静音.默认为5秒
          console.log(uID, "通话时检测到一定时间的静音" + vValue);
          break;
        case BriEvent_GetDTMFChar:// 线路接通时收到DTMF码事件
          console.log(uID, "线路接通时收到DTMF码事件" + vValue);
          break;
        case BriEvent_RemoteHook:// 拨号后,被叫方摘机事件
          console.log(uID, "拨号后,被叫方摘机事件" + vValue);
          break;
        case BriEvent_RemoteHang://对方挂机事件
          TV_HangUpCtrl(uID);
          console.log(uID, "对方挂机事件" + vValue);
          this.endCallFun(uID + 1)
          break;
        case BriEvent_Busy:// 检测到忙音事件,表示PSTN线路已经被断开
          console.log(uID, "检测到忙音事件,表示PSTN线路已经被断开" + vValue);
          break;
        case BriEvent_DialTone:// 本地摘机后检测到拨号音
          console.log(uID, "本地摘机后检测到拨号音" + vValue);
          break;
        case BriEvent_RingBack:// 电话机拨号结束呼出事件。
          console.log(uID, "电话机拨号结束呼出事件" + vValue);
          break;
        case BriEvent_MicIn:// MIC插入状态
          console.log(uID, "MIC插入状态" + vValue);
          break;
        case BriEvent_MicOut:// MIC拔出状态
          console.log(uID, "MIC拔出状态" + vValue);
          break;
        case BriEvent_FlashEnd:// 拍插簧(Flash)完成事件，拍插簧完成后可以检测拨号音后进行二次拨号
          console.log(uID, "拍插簧(Flash)完成事件，拍插簧完成后可以检测拨号音后进行二次拨号" + vValue);
          break;
        case BriEvent_RefuseEnd:// 拒接完成
          console.log(uID, "拒接完成" + vValue);
          this.endCallFun(uID + 1)
          break;
        case BriEvent_SpeechResult:// 语音识别完成 
          console.log(uID, "语音识别完成" + vValue);
          break;
        case BriEvent_OpenSoundFailed:// 启动声卡失败
          console.log(uID, "启动声卡失败" + vValue);
          break;
        case BriEvent_UploadSuccess://远程上传成功
          console.log(uID, "远程上传成功" + vValue);
          break;
        case BriEvent_UploadFailed://远程上传失败
          console.log(uID, "远程上传失败" + vValue);
          break;
        case BriEvent_EnableHook:// 应用层调用软摘机/软挂机成功事件
          console.log(uID, "应用层调用软摘机/软挂机成功事件" + vValue);
          break;
        case BriEvent_EnablePlay:// 喇叭被打开或者/关闭
          console.log(uID, "喇叭被打开或者/关闭" + vValue);
          break;
        case BriEvent_EnableMic:// MIC被打开或者关闭
          console.log(uID, "MIC被打开或者关闭" + vValue);
          break;
        case BriEvent_EnableSpk:// 耳机被打开或者关闭
          console.log(uID, "耳机被打开或者关闭" + vValue);
          break;
        case BriEvent_EnableRing:// 电话机跟电话线(PSTN)断开/接通
          console.log(uID, "电话机跟电话线(PSTN)断开/接通" + vValue);
          sessionStorage.setItem('deviceInfo', '电话机跟电话线(PSTN)断开/接通,请刷新重试！')
          sessionStorage.setItem('deviceInfoYes', '0')
          if (this.num1 == 1) {
            this.num1 = 2
            this.$confirm('电话机跟电话线(PSTN)断开/接通,请刷新重试！！', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '',
              type: 'error'
            }).catch(() => {
            });
          }
          break;
        case BriEvent_DoRecSource:// 修改录音源
          console.log(uID, "修改录音源" + vValue);
          break;
        case BriEvent_DoStartDial:// 开始软件拨号
          console.log(uID, "开始软件拨号" + vValue);
          break;
        case BriEvent_RecvedFSK:// 接收到FSK信号，包括通话中FSK/来电号码的FSK
          console.log(uID, "接收到FSK信号，包括通话中FSK/来电号码的FSK" + vValue);
          break;
        case BriEvent_PlugOut:
          console.log(uID, "设备移除");
          sessionStorage.setItem('deviceInfo', '设备移除,请刷新重试！')
          sessionStorage.setItem('deviceInfoYes', '0')
          if (this.num == 1) {
            this.num = 2
            let deviceInfo = "设备移除,请检查设备是否已经插入并安装了驱动,并且没有其它程序已经打开设备"
            alert(deviceInfo)
          }
          break;
        case BriEvent_DevErr://设备错误
          console.log(uID, "设备错误" + decodeURIComponent(vValue));
          sessionStorage.setItem('deviceInfo', '设备错误,请刷新重试！')
          sessionStorage.setItem('deviceInfoYes', '0')
          if (this.num == 1) {
            this.num = 2
            let deviceInfo = "设备错误,请检查设备是否已经插入并安装了驱动,并且没有其它程序已经打开设备"
            alert(deviceInfo)
          }
          break;
        default:
          if (uEventType < BriEvent_EndID)
            console.log(uID, "忽略其它事件发生:ID=" + uEventType + vValue);
          if (!this.dialogVisible) {
            this.incomingCall.phone == ''
          }
          break;
      }
    },
    // 判断是否发送挂机短信
    determineSending(uID){
      let i=uID + 1
      let phone='';
      if(i==1){
        phone=this.phoneEnd1;
      }
      if(i==2){
        phone=this.phoneEnd2;
      }
      console.log('\x1b[32m%s\x1b[0m', '获取到线路来电号码：'+phone+'线路：'+i);
      if(phone){//存在代表有挂机短信
        GetDzSellerConf({// 获取录音设置
          service: "App.Seller.GetDzSellerConf",
          sid:localStorage.getItem("sellerid"),
        }).then(res=>{
          if(res.data.data.is_close_sms=='1'){
            if (phone.length != '11') {
              this.$message({
                message: '挂机短信发送失败，手机号码不正确',
                type: 'error'
              });
              return false
            }
            console.log('\x1b[32m%s\x1b[0m', '开启挂机短信设置，需要发送挂机短信---号码：'+phone+'线路：'+i);
            this.closeSmsFun(phone)//挂机短信开启，发送挂机短信
          }else{
            console.log('\x1b[32m%s\x1b[0m', '没有开启挂机短信设置');
          }
          if(i==1){
            this.phoneEnd1 = '';
          }
          if(i==2){
            this.phoneEnd2 = '';
          }
        })
      }else{
        console.log('\x1b[32m%s\x1b[0m', '不是来电，是拨号的挂机，线路：'+i);
      }
    },
    //第一个来电结束，终止第一个来电存储
    endCallFun (originalUid) {
      localStorage.removeItem('phoneLine');
    },
    // 获取年月日时分秒
    getTime () {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return formattedDateTime;
    },
    //保存PC消息(保存来电记录) 
    recordCall (obj) {
      console.log('保存来电记录传参', { ...obj });
      SavePcReserveMessage({
        service: "App.Reserve.SavePcReserveMessage",
        sellerid: localStorage.getItem("sellerid"),
        type: '1',
        content: JSON.stringify(obj),
        push_status:'1',
      }).then((res) => {
        this.$store.commit("getLengthFun")
      })
    },
    //获取是否可以预订的权限
    getManagerList () {
      let role = localStorage.getItem('role_name');
      let params = {
        service: "App.Seller.GetAuthUserLists",
        sellerid: localStorage.getItem("sellerid"),
      };
      manager_list(params).then((res) => {
        if (res.status == 200) {
          var temp = [];
          for (let e in res.data.data) {
            res.data.data[e].forEach((item, index) => {
              temp.push(item);
            });
          }
          let obj = temp.filter(i=>{return i.id == localStorage.getItem('uid')})
          let bookPower;
          if(obj.length>0){
            bookPower = obj[0].account_type;
          }
          this.addBookRole = (this.$roles.addBook.indexOf(role) != -1 && bookPower != '3')? false : true;
        } else {
          this.$toast.fail(res.data.msg);
        }
      });
    },
  },
  beforeDestroy () {
    // 组件销毁时清除计时器，避免内存泄漏
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.$store.dispatch('disconnectWebSocket')
  },
  destroyed () {
    this.$store.dispatch('disconnectWebSocket')
  },
};
</script>
<style lang='scss' scoped>
* {
  margin: 0;
  padding: 0;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
  background: rgba(22, 65, 89, 1);
  box-sizing: border-box;
  input {
    outline: none;
  }
  li {
    list-style: none;
  }
}
// .dialogContent {
//   .topImg {
//     position: absolute;
//     width: 32px;
//     height: 32px;
//     right: 32px;
//     top: 2.5%;
//     margin-left: 10px;
//   }
//   .topBox {
//     display: flex;
//     border-bottom: 1px solid #90979f;
//     .infoLeft {
//       margin-right: 5%;
//       .phone {
//         display: flex;
//         .phoneImg {
//           width: 47px;
//           height: 50px;
//           margin: 0 10px 0 0;
//         }
//         .texts {
//           color: #212e3e;
//           font-family: PingFang SC;
//           font-weight: 600;
//           font-size: 24px;
//         }
//       }
//       .frequency {
//         display: flex;
//         align-items: center;
//         margin-top: 30px;
//         padding-bottom: 30px;
//         .text {
//           color: #212e3e;
//           font-family: PingFang SC;
//           font-weight: 600;
//           font-size: 18px;
//         }
//       }
//     }
//     .rightBox {
//       max-width: 53%;
//       display: flex;
//       flex-direction: column;
//       .info {
//         color: #212e3e;
//         font-family: PingFang SC;
//         font-weight: bold;
//         font-size: 16px;
//         line-height: 28px;
//       }
//     }
//   }
//   .boxInfo {
//     max-height: 300px;
//     overflow-y: auto;
//   }
//   .details {
//     width: 96%;
//     display: flex;
//     padding: 15px 0 10px 0;
//     border-bottom: 1px solid #90979f;
//     flex-direction: column;
//     .itemBox {
//       width: 100%;
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       .itemLeft {
//         width: 53%;
//         display: flex;
//         flex-direction: column;
//       }
//       .rightBtns {
//         display: flex;
//         flex-direction: column;
//         .send {
//           background: #164159;
//           color: #fff;
//           font-family: PingFang SC;
//           font-weight: 600;
//           font-size: 18px;
//           margin-bottom: 12px;
//           padding: 0;
//           border-radius: 8px;
//           height: 40px;
//           line-height: 40px;
//         }
//         .book {
//           display: flex;
//           width: 100%;
//           justify-content: space-between;
//           .el-button {
//             border: 1px solid #164159;
//             color: #164159;
//             font-family: PingFang SC;
//             font-weight: 600;
//             font-size: 18px;
//             padding: 0 24px;
//             border-radius: 8px;
//             height: 40px;
//             line-height: 40px;
//           }
//         }
//       }
//     }
//     .text {
//       color: #000000;
//       font-family: PingFang SC;
//       font-size: 18px;
//       line-height: 38px;
//       text-align: left;
//     }
//     .text1 {
//       color: #212e3e;
//       font-family: PingFang SC;
//       font-weight: 600;
//       font-size: 18px;
//       display: flex;
//       align-items: center;
//     }
//   }
//   .line {
//     width: 1px;
//     height: 15px;
//     border: 1px solid #212e3e;
//     margin: 0 16px;
//   }
// }
::v-deep .el-dialog {
  border-radius: 16px;
  margin-top: 9vh !important;
}
::v-deep .el-dialog__header {
  background: #164159;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}
::v-deep .el-dialog__title {
  color: #fff;
  font-size: 28px;
  width: 90%;
  display: flex;
}
::v-deep .el-dialog__body {
  padding: 30px 32px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
::v-deep .btnBookRole {
  width: 45%;
  color: #ffffff;
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 18px;
  padding: 0 24px;
  background: #164159;
  border-radius: 8px;
  height: 40px;
  line-height: 40px;
}
::v-deep .openClose {
  position: fixed;
  top: 182px;
  left: 527px;
  z-index: 400000 !important;
}
.btnsDid {
  font-size: 18px;
  padding: 0 24px;
  border-radius: 8px;
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  background: rgba(22, 65, 89, 0.5) !important;
  color: #fff !important;
}

::v-deep .residueDialog .el-dialog__header {
  background: #fff !important;
  padding-top: 32px !important;
}
::v-deep .residueDialog .el-dialog__title {
  color: #000 !important;
  font-weight: 600;
  font-size: 24px;
}
::v-deep .residueDialog .el-dialog__wrapper {
  z-index: 3030 !important;
}
::v-deep .residueDialog .el-dialog__wrapper {
  z-index: 3030 !important;
}
.public {
  width: 100%;
  text-align: center;
}
.prompt {
  color: #e34d59;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 10px;
}
.residueTexts {
  color: #515151;
  font-family: PingFang SC;
  font-size: 20px;
  line-height: 30px;
}
.lastTexts {
  margin-top: 20px;
}
.tapTexts {
  margin-bottom: 20px;
}
.closeBtn {
  width: 80%;
  color: #001f97;
  font-weight: 600;
  font-size: 20px;
  border: none !important;
  border-top: 1px solid #e7e7e7 !important;
  padding: 16px 15px !important;
}
.closeBtn:focus,
.closeBtn:hover {
  color: #001f97;
  font-weight: 600;
  font-size: 20px;
  border-color: transparent;
  background-color: transparent;
}
::v-deep .disabledBtn {
  color: #ffffff !important;
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 18px;
  background: rgba(22, 65, 89, 0.5);
  border-radius: 8px;
  line-height: 40px;
  height: 40px;
}
::v-deep .disabledBtn:hover {
  background: rgba(22, 65, 89, 0.5);
  color: #ffffff;
}
.sendDisabledBtn {
  color: #ffffff;
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 18px;
  background: rgba(22, 65, 89, 0.5);
  border-radius: 8px;
  line-height: 40px;
  height: 40px;
  margin-bottom: 12px;
}
::v-deep .sendDisabledBtn:hover {
  background: rgba(22, 65, 89, 0.5);
  color: #ffffff;
}
.messageBox {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 400px;
  min-height: 295px;
  background: #fff;
  color: #212e3e;
  border: 2px solid #ffffff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  .messageTop {
    background: #164159;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 30px 14px 12px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    h1 {
      font-size: 24px;
    }
    img {
      width: 40px;
      height: 40px;
      margin-left: 20px;
    }
  }
  .messageBottom {
    font-size: 18px;
    line-height: 22px;
    padding: 15px;
    hr {
      margin: 10px 0;
    }
  }

  .sure {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto 10px auto;

    .useBtn,
    .send {
      width: 50%;
      height: 40px;
      border-radius: 8px;
      color: rgb(22, 65, 89);
      font-size: 17px;
      border: 2px solid rgba(22, 65, 89, 1);
      box-sizing: border-box;
      text-align: center;
      cursor: pointer;
      padding: 0;
      margin-right: 5%;
    }
    .send {
      background: rgba(22, 65, 89, 1);
      color: white;
    }
  }
}
.popContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
}
::v-deep .el-input--small .el-input__inner {
  height: 40px;
  line-height: 40px;
  border-color: #000;
}
</style>
