let socket = null;
let timer = null;
import { unixtoTime } from '../../assets/js/utils'
import { GetPcReserveMessage } from "@/api/api"

export default {
  state: {
    message: null,
    sellerid: '',
    account: '',
    uid: '',
    close: false,
    time: 50000,
    num: 0,
    errorNum: 0,//失败次数 失败5次之后不在重新请求
    messageObj: {
      ddateInfo: ''
    },
    lengthNum: 0,
  },
  mutations: {
    //开启
    SOCKET_ONOPEN (state, info) {
      console.log('开启WebSocket连接', info);
      // 开启心跳定时器
      timer = setInterval(() => {
        info.workerID = localStorage.getItem('workerID')
        info.connectionID = localStorage.getItem('connectionID')
        info.id = localStorage.getItem('uid')
        info.HEARTBEAT_TIME = parseInt(new Date().getTime() / 1000);
        console.log('info信息', { ...info });
        if (socket && info.connectionID && info.workerID && info.id) {
          console.log('发送心跳信息', { ...info });
          try {
            socket.send(JSON.stringify(info));
          } catch (error) {
            console.error('发送数据时发生错误1：', error);
          }
        } else {
          console.log('未发送心跳信息', { ...info });
        }
      }, state.time);
    },
    // 关闭
    SOCKET_ONCLOSE (state, event) {
      console.log('WebSocket连接已关闭');
      if (socket && state.close) {//浏览器关闭
        clearInterval(timer);// 关闭心跳定时器
      } else {//连接错误 || 服务器连接已关闭 || 收到消息返回信息不对
        this.commit('againRequest')
      }
    },
    // 收到消息
    SOCKET_ONMESSAGE (state, message) {
      state.message = message;
      console.log('收到消息:', { ...message });
      if ((message && message.workerID) || message.workerID == 0) localStorage.setItem('workerID', message.workerID)
      if ((message && message.connectionID || message.connectionID == 0)) localStorage.setItem('connectionID', message.connectionID)
      if (message.id) {
        state.messageObj = message;
        state.messageObj.ddateInfo = unixtoTime(message.ddate, "YYYY-MM-DD")
        state.messageObj.ddateSolt = message.ddate_solt == '1' ? '早餐' : message.ddate_solt == '2' ? '午餐' : message.ddate_solt == '3' ? '晚餐' : '';
        state.messageObj.contentRid = message.id
        state.messageObj.messageId = message.message_id
        this.commit('getLengthFun')
      }
      setTimeout(() => {// 5分钟后清空消息的值
        state.messageObj = null;
      }, 5 * 60 * 1000);
      if (message.success != 'HEARTBEAT_OK') {
        console.log('收到消息---需要重连');
        this.commit('againRequest')
      }
      state.num += 1;
      if (state.num == 1 && localStorage.getItem('uid')) {//第一次连接上以后，立刻发送信息
        let info = {
          workerID: localStorage.getItem('workerID'),
          connectionID: localStorage.getItem('connectionID'),
          id: localStorage.getItem('uid'),
          sellerid: localStorage.getItem('sellerid'),
          account: localStorage.getItem("account"),
          CONNECT: parseInt(new Date().getTime() / 1000),
        };
        console.log('info信息', { ...info });
        if (socket && info.connectionID && info.workerID) {
          console.log('发送心跳信息', { ...info });
          try {
            socket.send(JSON.stringify(info));
          } catch (error) {
            console.error('发送数据时发生错误2：', error);
          }
        } else {
          console.log('未发送心跳信息', { ...info });
        }
      }
    },
    // 连接错误
    SOCKET_ONERROR (state, event) {
      console.error('WebSocket连接错误:', event+'失败次数'+state.errorNum);
      if (state.errorNum < 6) {
        this.commit('againRequest')
      }
    },
    // 重新开启ws
    againRequest (state) {
      console.log('重新开启ws');
      clearInterval(timer);// 关闭心跳定时器
      localStorage.removeItem('workerID')
      localStorage.removeItem('connectionID')
      setTimeout(() => {
        this.dispatch('onopenWebSocket', {
          sellerid: localStorage.getItem("sellerid"),
          account: localStorage.getItem("account"),
          uid: localStorage.getItem("uid"),
        });
      }, state.time);
    },
    //获取消息中心长度
    getLengthFun (state) {
      GetPcReserveMessage({
        service: 'App.Reserve.GetPcReserveMessage',
        sellerid: localStorage.getItem("sellerid"),
        page: 1,
        offset: 8,
      }).then((res) => {
        if(res.data.ret==200){
          state.lengthNum = res.data.data.count
        }
      })
    },
    //清空消息弹框数据，关闭预订消息弹框
    setmessageObj (state) {
      state.messageObj = null;
    },
  },
  actions: {
    // 创建WebSocket连接
    onopenWebSocket ({ state, commit }, data) {
      state.num = 0
      let hasExecuted = false;
      state.sellerid = data.sellerid;
      state.account = data.account;
      state.uid = data.uid;
      if (data.login == '1') state.errorNum = 0;
      if(data.uid){
        socket = new WebSocket(`wss://yuding.colorcun.com/wss?sellerid=${data.sellerid}&account=${data.account}&id=${data.uid}`);
        // 监听WebSocket事件
        socket.onopen = (event) => {
          console.log('监听WebSocket事件-----连接ws', event);
          commit('SOCKET_ONOPEN', { sellerid: state.sellerid, account: state.account, });
        };
        socket.onclose = (event) => {
          console.log('监听WebSocket事件-----关闭ws', event);
          if (!hasExecuted) {
            commit('SOCKET_ONCLOSE', event);
            hasExecuted = true;
          }
        };
        socket.onmessage = (event) => {
          console.log(event);
          if(event.data=='此链接已关闭\n'){
            commit('SOCKET_ONMESSAGE', event.data);
          }else{
            commit('SOCKET_ONMESSAGE', JSON.parse(event.data));
          }
        };
        socket.onerror = (event) => {
          state.errorNum += 1;
          console.log('监听WebSocket事件-----连接ws错误' + state.errorNum + '次数');
          if (!hasExecuted) {
            commit('SOCKET_ONERROR', event);
            hasExecuted = true;
          }
        }
      };
    },
    // 关闭浏览器  关闭WebSocket连接
    disconnectWebSocket ({ state }) {
      if (socket) {
        state.close = true
        socket.send(JSON.stringify('close'));// 发送心跳消息
        socket.close();
      }
    }
  }
};