// 对axios进行二次封装（设置请求/响应拦截器）
import axios from "axios";
import { Message } from 'element-ui'
import $router from '../router/index'
import $store from '../store'

// 请求拦截器
const service = axios.create({
    baseURL: "",
    withCredentials: true,
    timeout: 10000,
    // headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    //     },
});
service.interceptors.request.use((config) => {//拦截器
    config.headers['Authorization'] = 'Bearer'
    return config;
}, (error) => {//请求报错时执行
    return Promise.reject(error);
})

// 响应拦截器
service.interceptors.response.use((res) => {//响应拦截器返回值是res
    return res;
}, (error) => {//从服务器返回的响应报错
    if (error && error.response && error.response.status == 401) {
        //登陆过期  需要删除token   然后跳转登录页  
        Message.error('登陆过期！')
        //先删除token
        // $store.commit('del_token');
        $router.push('/login')
    }
    else if (error && error.response && error.response.status == 404) {
        // 访问路径有误
        Message.error('访问路径有误')
    }
    else if (error && error.response && error.response.status == 500) {
        // 服务器内部错误
        Message.error('服务器内部错误')
    }
    return Promise.reject(error);
})

export default service;
