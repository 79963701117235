import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      title: '周到安排-预订首页',
      requiresAuth: true
    }
  },
  {
    path: '/infoCenter',
    name: 'InfoCenter',
    component: () => import('../views/infoCenter.vue'),
    meta: {
      title: '周到安排-消息中心',
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '周到安排-登录'
    }
  },
  {
    path: '/setreserve',
    name: 'setReserve',
    component: () => import('../views/setReserve.vue'),
    meta: {
      title: '周到安排-变更预订',
      requiresAuth: true
    }
  },
  {
    path: '/seller',
    name: 'Seller',
    component: () => import('../views/Seller.vue'),
    meta: {
      title: '周到安排-选择商家',
    }
  },
  {
    path: '/list',
    name: 'List',
    component: () => import('../views/List.vue'),
    meta: {
      title: '周到安排-预订列表',
      requiresAuth: true
    }
  },
  {
    path: '/PrintTable',
    name: 'PrintTable',
    component: () => import('../views/PrintTable.vue'),
    meta: {
      title: '周到安排-打印预订列表',
      requiresAuth: true
    }
  },
  {
    path: '/SwitchSeller',
    name: 'SwitchSeller',
    component: () => import('../views/SwitchSeller.vue'),
    meta: {
      title: '周到安排-选择店铺',
      requiresAuth: true
    }
  },
  {
    path: '/',
    redirect: '/login'
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 路由拦截，判断是否需要登录
router.beforeEach((to, from, next) => {
 // console.log("title",document.title)
 // 通过requiresAuth判断当前路由导航是否需要登录
 if (to.matched.some(record => record.meta.requiresAuth)) {
 let token = localStorage.getItem('sellerid')
 let uid = localStorage.getItem('uid')
 // console.log("token",token)
 // 若需要登录访问，检查是否为登录状态
 if (!token || !uid) {
  next({
  path: '/login',
  query: { redirect: to.fullPath }
  })
 } else {
  next()
 }
 } else {
 next() // 确保一定要调用 next()
 }
})
/* 路由异常错误处理，尝试解析一个异步组件时发生错误，重新渲染目标页面 */
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  console.log('routerError',{pattern,error,isChunkLoadFailed,targetPath});
  if(isChunkLoadFailed){
    router.replace(targetPath);
  }
})  
export default router
