<template style="position: fixed;top: 0;left: 0;right: 0;bottom: 0;background: rgba(0, 0, 0, 0.3);">
  <div class="reserveNoArrange">
    <div class="titleBox">
      <h1 class="title">拒绝订单</h1>
      <span class="close" @click="closeClick">x</span>
    </div>
    <div class="info">拒绝后不可撤销，请谨慎操作！是否确定拒绝该预订？</div>
    <div class="infoBottom">
      <button class="back" @click="closeClick()">返回</button>
      <el-button
        class="send"
        @click="GetDzSellerConfFun()"
        v-loading.fullscreen.lock="loading"
        element-loading-text="操作中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        >确定拒绝</el-button
      >
    </div>
  </div>
</template>
<script>
import {
  SetReserveStatus,
  SetPcReserveMessageById,
} from "../api/api";
export default {
  data () {
    return {
      loading: false,
    };
  },
  props: ["Detail"],
  created () {
    console.log('详情数据', { ...this.Detail });
  },
  mounted () {
    this.$refs.mask ? this.$refs.mask.style.height = window.document.getElementById("app").clientHeight + "px" : '';
  },
  methods: {
    // 点击确认
    GetDzSellerConfFun () {
      this.loading = true
      SetReserveStatus({
        service: "Android.Customer.SetReserveStatus",
        rid: this.Detail.contentRid,
        status: '6',//2：确认到店（到店），3：用餐完成（离店），4：取消预定,5:分配包间：6：拒单
      }).then((res) => {
        if (res.data.ret == 200) {
          this.setmessageFun(this.Detail.messageId)
          this.$message.success('操作成功！');
        } else {
          this.$message.error('操作失败！');
        }
        this.$emit("noArrangeClose", {
          noShow: false,
          infoShow: false,
          data: this.Detail,
        });
      })
    },
    // 设置PC消息状态为已处理
    setmessageFun (messageId) {
      SetPcReserveMessageById({
        service: 'App.Reserve.SetPcReserveMessageById',
        id: messageId,
        status: '2',//消息状态 0 未读 1 已读 2 已处理
      }).then(res => {
        if (res.data.ret == 200) {
          console.log('设置PC消息状态为已处理');
        }
      })
    },
    closeClick () {
      this.$emit("noArrangeClose", {
        noShow: false,//是否显示拒绝框
        infoShow: true,//是否显示预订消息弹出框（拒绝完成或者安排包间时，关闭）
        data: this.Detail,//在消息中心中可以刷新预订状态
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.reserveNoArrange {
  z-index: 2;
  position: relative;
  width: 400px;
  min-height: 200px;
  border-radius: 16px;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  left: calc(50% - 190px);
  top: calc(50% - 280px);
  padding-bottom: 30px;
  .titleBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(22, 65, 89, 1);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  .close {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid white;
    font-size: 22px;
    text-align: center;
    line-height: 22px;
    cursor: pointer;
    color: #fff;
    margin: 0 35px 0 0;
  }
  .title {
    height: 68px;
    border-radius: 16px 16px 0 0;
    font-size: 30px;
    font-weight: 300;
    color: rgb(231, 241, 255);
    text-indent: 30px;
    line-height: 68px;
  }
  .infoBottom {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  div.popContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }
  .back {
    width: 45%;
    height: 40px;
    border-radius: 8px;
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    cursor: pointer;
    color: #fff;
    background: #164159;
  }

  .send {
    width: 45%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid;
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    cursor: pointer;
    padding: 0;
    margin: 0 auto;
    color: rgba(22, 65, 89, 1);
    background: #fff;
    border: 1px solid #164159;
  }
}
.info {
  width: 60%;
  padding: 50px 0;
  color: #000000;
  font-family: PingFang SC;
  font-weight: 600;
  font-size: 18px;
  margin: 0 auto;
}
</style>
