//URL中所有参数可以以p1=v1&p2=v2赋值，以post格式传送
////接受文件在asp,php的参数为file="文件名
//strFilePath问文件本地路径
//nextFunc下一条函数
export function UploadFile(  strUrl,  strFilePath,nextFunc)
{
	var nResult = -99;
	var paraObj = {};
	paraObj.a = strUrl;
	paraObj.b = strFilePath;
	
	nResult = sendCmd("UploadFile",paraObj,nextFunc);
	return nResult;			
}
export var BriEvent_PhoneHook=1;// 本地电话机摘机事件
export var BriEvent_PhoneHang=2;// 本地电话机挂机事件
// 外线通道来电响铃事件
// BRI_EVENT.lResult		为响铃次数
// BRI_EVENT.szData[0]="0"	开始1秒响铃
// BRI_EVENT.szData[0]="1"	为1秒响铃完成，开始4秒静音
export var BriEvent_CallIn=3;
// 得到来电号码
// BRI_EVENT.lResult		来电号码模式(CALLIDMODE_FSK/CALLIDMODE_DTMF
// BRI_EVENT.szData			保存的来电号码
// 该事件可能在响铃前,也可能在响铃后
export var BriEvent_GetCallID = 4;
export var BriEvent_StopCallIn = 5;// 对方停止呼叫(产生一个未接电话)
export var BriEvent_DialEnd = 6;// 调用开始拨号后，全部号码拨号结束
export var BriEvent_SendCallIDEnd = 11;// 给本地设备发送震铃信号时发送号码结束
export var BriEvent_RingTimeOut = 12;//给本地设备发送震铃信号时超时//默认不会超时
//正在内线震铃
//BRI_EVENT.lResult	   已经响铃的次数
// BRI_EVENT.szData[0]="0"	开始一次响铃
// BRI_EVENT.szData[0]="1"	一次响铃完成，准备静音
export var BriEvent_Ringing = 13;
// 通话时检测到一定时间的静音.默认为5秒
export var BriEvent_Silence = 14;

// 线路接通时收到DTMF码事件
// 该事件不能区分通话中是本地话机按键还是对方话机按键触发
export var BriEvent_GetDTMFChar = 15;

// 拨号后,被叫方摘机事件（该事件仅做参考,原因如下：）
// 原因：
// 该事件只适用于拨打是标准信号音的号码时，也就是拨号后带有标准回铃音的号码。
// 如：当拨打的对方号码是彩铃(彩铃手机号)或系统提示音(179xx)都不是标准回铃音时该事件无效。
// 
export var BriEvent_RemoteHook = 16;

// 挂机事件
// 如果线路检测到被叫方摘机后，被叫方挂机时会触发该事件，不然被叫方挂机后就触发BriEvent_Busy事件
// 该事件或者BriEvent_Busy的触发都表示PSTN线路已经被断开
export var BriEvent_RemoteHang = 17;

// 检测到忙音事件,表示PSTN线路已经被断开
export var BriEvent_Busy = 18;

// 本地摘机后检测到拨号音
export var BriEvent_DialTone = 19;

// 只有在本地话机摘机，没有调用软摘机时，检测到DTMF拨号
export var BriEvent_PhoneDial = 20;

// 电话机拨号结束呼出事件。
// 也就时电话机拨号后接收到标准回铃音或者15秒超时
// BRI_EVENT.lResult=0 检测到回铃音// 注意：如果线路是彩铃是不会触发该类型
// BRI_EVENT.lResult=1 拨号超时
// BRI_EVENT.lResult=2 动态检测拨号码结束(根据中国大陆的号码规则进行智能分析，仅做参考)
// BRI_EVENT.szData[0]="1" 软摘机拨号结束后回铃了
// BRI_EVENT.szData[0]="0" 电话机拨号中回铃了
export var BriEvent_RingBack = 21;

// MIC插入状态
// 只适用具有该功能的设备
export var BriEvent_MicIn = 22;
// MIC拔出状态
// 只适用具有该功能的设备
export var BriEvent_MicOut = 23;

// 拍插簧(Flash)完成事件，拍插簧完成后可以检测拨号音后进行二次拨号
// BRI_EVENT.lResult=TEL_FLASH  用户使用电话机进行拍叉簧完成
// BRI_EVENT.lResult=SOFT_FLASH 调用StartFlash函数进行拍叉簧完成
export var BriEvent_FlashEnd = 24;

// 拒接完成
export var BriEvent_RefuseEnd = 25;

// 语音识别完成 
export var BriEvent_SpeechResult = 26;

// 启动声卡失败
export var BriEvent_OpenSoundFailed = 35;

// 产生一个PSTN呼入/呼出日志
export var BriEvent_CallLog = 36;

//检测到连续的静音
//使用QNV_GENERAL_CHECKSILENCE启动后检测到设定的静音长度
export var BriEvent_RecvSilence = 37;

//检测到连续的声音
//使用QNV_GENERAL_CHECKVOICE启动后检测到设定的声音长度
export var BriEvent_RecvVoice = 38;

//远程上传事件
export var BriEvent_UploadSuccess = 50;
export var BriEvent_UploadFailed = 51;
// 远程连接已被断开
export var BriEvent_RemoteDisconnect = 52;

//HTTP远程下载文件完成
//BRI_EVENT.lResult	   启动下载时返回的本次操作的句柄
export var BriEvent_DownloadSuccess	=60;
export var BriEvent_DownloadFailed	=61;

//线路检测结果
//BRI_EVENT.lResult 为检测结果信息
export var BriEvent_CheckLine = 70;

// 应用层调用软摘机/软挂机成功事件
// BRI_EVENT.lResult=0 软摘机
// BRI_EVENT.lResult=1 软挂机			
export var BriEvent_EnableHook = 100;
// 喇叭被打开或者/关闭
// BRI_EVENT.lResult=0 关闭
// BRI_EVENT.lResult=1 打开			
export var BriEvent_EnablePlay = 101;
// MIC被打开或者关闭	
// BRI_EVENT.lResult=0 关闭
// BRI_EVENT.lResult=1 打开			
export var BriEvent_EnableMic = 102;
// 耳机被打开或者关闭
// BRI_EVENT.lResult=0 关闭
// BRI_EVENT.lResult=1 打开			
export var BriEvent_EnableSpk = 103;
// 电话机跟电话线(PSTN)断开/接通(DoPhone)
// BRI_EVENT.lResult=0 断开
// BRI_EVENT.lResult=1 接通		
export var BriEvent_EnableRing = 104;
// 修改录音源 (无用/保留)
// BRI_EVENT.lResult 录音源数值
export var BriEvent_DoRecSource = 105;
// 开始软件拨号
// BRI_EVENT.szData	准备拨的号码
export var BriEvent_DoStartDial = 106;

export var BriEvent_EnablePlayMux = 107;

// 接收到FSK信号，包括通话中FSK/来电号码的FSK		
export var BriEvent_RecvedFSK = 198;
//设备错误
export var BriEvent_DevErr = 199;
export var BriEvent_PlugOut = 195;	// 设备可能被拔掉了
export var BriEvent_EndID=500;