<template style="position: fixed;top: 0;left: 0;right: 0;bottom: 0;background: rgba(0, 0, 0, 0.3);">
  <div class="reserveCancel">
    <div class="main-right-box">
      <h1 class="title">取消预订</h1>
      <span class="close" @click="closeClick">x</span> 
      <div class="reserveInfo">
        <el-select v-model="inquire.selectData" class="seleClsas" placeholder="选择取消原因" @change="getCancelEventType($event)">
	       	<el-option
          	v-for="(item,index) in cancelTempTags"
          	:label="item"
          	:value="item"
			      :key="index"
        	>
        	</el-option>
        </el-select>
        <textarea
          class="news"
          v-if="inquire.selectData=='其他'"
          cols="30"
          rows="4"
          placeholder="点击录入订单取消原因"
          v-model.trim="cancelValue"
        ></textarea>
        <el-select v-if="depositList && depositList.length>0" v-model="inquire.depositText" class="seleClsas" placeholder="选择订金去向" @change="getDepositEventType($event)">
          <el-option
            v-for="(item,index) in depositTempTags"
            :label="item"
            :value="item"
            :key="index"
          >
          </el-option>
        </el-select>
        <textarea
          class="news"
          v-if="inquire.depositText=='其他'"
          cols="30"
          rows="4"
          placeholder="点击录入订金走向说明文字"
          v-model.trim="depositValue"
        ></textarea>
      </div>
      <div class="tsText">取消后不可撤销，请谨慎操作！</br>是否确定取消该预订？</div>
      <div class="backBox">
        <button class="back" @click="closeClick()">返回</button>
        <el-button class="send" 
          @click="GetDzSellerConfFun()"
          v-loading.fullscreen.lock="loading"
          :element-loading-text="loadingText"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          element-loading-custom-class="boxStyle"
        >确定取消</el-button> 
      </div> 
    </div>
    <canvas ref="canvas" width="320" height="260"></canvas>
    <video ref="video" width="340" height="280" autoplay></video>
  </div>  
</template>
<script>
import { GetUploadToken,GetReserveDepositByRid,SetReserveDeposit,order_status, GetDzSellerConf, WxSendCancelSms } from "../api/api";
import eventBus from "../util/eventBus";
import Vue from 'vue'
import * as qiniu from 'qiniu-js';
export default {
  data () {
    return {
      cancelTempTags: ["顾客取消", "顾客行程有变", "信息填写错误", "操作失误","其他"],
      depositTempTags: ["订金已退", "订金充入会员卡", "订金留店下次使用", "抵餐费","其他"],
      cancelInfo: null,
      depositInfo:null,
      inquire: { selectData: "",depositText:"" },
      loading: false,
      cancelValue:'',
      depositValue:'',
      depositList:[],
      cancelTemp:'',//是否开启取消预订发送短信
      qiniuToken:'',//七牛云token
      showPhone:false,//是否成功开启摄像头，true开启成功
      loadingText:'取消预订中，请稍后···',
    };
  },
  props: ["showBox", "Detail", "dialog"],
  return: {
    name: {},
  },
  created () {
    this.getDepositFun()
    this.callCamera()// 调用摄像头
    this.getToken(localStorage.getItem('sellerid'))// 获取七牛云token
  },
  mounted () {
    Vue.prototype.$ELEMENT = { size: "small", zIndex: 3000 };
    this.$refs.mask ? this.$refs.mask.style.height = window.document.getElementById("app").clientHeight + "px" : '';
  },
  methods: {
    // 调用摄像头
    callCamera() {
      this.getTakePhotoFun().then(openTakePhoto => {
        console.log('openTakePhoto111',openTakePhoto);
        this.showPhone = false;
        if(openTakePhoto=='1'){
          navigator.mediaDevices.getUserMedia({// H5调用电脑摄像头API
            video: true
          })
          .then(success => {
            console.error('摄像头开启成功')
            this.$refs['video'].srcObject = success// 摄像头开启成功
            this.$refs['video'].play() // 实时拍照效果
            this.showPhone=true
          })
          .catch(error => {
            console.error('摄像头开启失败，请检查摄像头是否可用！')
          })
        }else{
          console.log('没有开启拍照功能');
        }
      });
    },
    // 获取订金列表
    getDepositFun () {
      let params = {
        service: "App.Reserve.GetReserveDepositByRid",
        rid:this.Detail.id,
      };
      GetReserveDepositByRid(params).then((res) => {
        if (res.status == 200) {
          this.depositList = res.data.data
        }
      })
    },
    // 获取七牛云token
    getToken(sid){
      GetUploadToken({
          service: "App.Qiniu.GetUploadToken",
          sid,
      }).then(res => {
        this.qiniuToken = res.data.data.token
      })
    },
    // 获取是否打开摄像头信息
    async getTakePhotoFun(){
      const res = await GetDzSellerConf({
        service: "App.Seller.GetDzSellerConf",
        sid: localStorage.getItem('sellerid'),
      });
      let openTakePhoto = '0';
      if (res.data.data && res.data.data.is_take_photo_cancel === '1') {
        openTakePhoto = '1';
      } else {
        openTakePhoto = '0';
      }
      return openTakePhoto;
    },
    GetDzSellerConfFun () {
      if (!this.cancelInfo) {
        this.loading = false;
        this.$message.warning('请选择取消原因');
        return false;
      }
      if(this.inquire.selectData=='其他' && this.cancelValue==''){
        this.loading = false;
        this.$message.warning('请录入订单取消原因');
        return false;
      }
      if(this.depositList && this.depositList.length>0 && !this.depositInfo){
        this.loading = false;
        this.$message.warning('请选择选择订金去向');
        return false;
      }
      if(this.inquire.depositText=='其他' && this.depositList && this.depositList.length>0 && this.depositValue==''){
        this.loading = false;
        this.$message.warning('请录入订金走向说明文字');
        return false;
      }
      if(this.cancelInfo=='其他'){
        this.cancelInfo=this.cancelValue;
      }
      if(this.depositInfo=='其他'){
        this.depositInfo=this.depositValue;
      }
      this.loading = true;
      GetDzSellerConf({
        service: "App.Seller.GetDzSellerConf",
        sid: localStorage.getItem("sellerid"),
      }).then((res) => {
        if (res.data.ret == '200') {
          this.cancelTemp = res.data.data.is_open_cancel_temp
          console.log('是否成功开启摄像头',this.showPhone);
          if(res.data.data && res.data.data.is_take_photo_cancel === '1' && this.showPhone){//开启拍照
            this.photograph()
          }else{//直接取消
            this.fixCancel()
          }
        } else {
          this.loading = false;
        }
      })
    },
    // 拍照
    photograph() {
      let ctx = this.$refs['canvas'].getContext('2d')
      // 把当前视频帧内容渲染到canvas上
      ctx.drawImage(this.$refs['video'], 0, 0, 340, 280)
      // 转base64格式、图片格式转换、图片质量压缩
      let imgBase64 = this.$refs['canvas'].toDataURL('image/jpeg', 0.7) // 由字节转换为KB 判断大小
      const canvas = this.$refs.canvas ;
      const image = new Image()// 转为图片
      image.src = canvas.toDataURL('image/png')
      var file = this.dataURLtoFile(imgBase64, 'aaa');
      this.uploadFile(file)
    },
    uploadFile(file) {
      let that=this;
      let num=Math.floor(new Date().getTime()/1000);
      const key = 'recording/img'+num+'.png'; // 可以自定义文件名
      const token = this.qiniuToken; // 从服务端获取七牛云的上传凭证
      const config = {
        useCdnDomain: true,
        region: qiniu.region.z0,
        uphost: 'up-z0.qiniup.com'
      };
      const observable = qiniu.upload(file, key, token, null, config);
      const observer = {
        next(res) {
          console.log('上传进度：', res.total.percent);// 上传进度
          setTimeout(() => {
            that.loadingText = '您的网络有点延迟，请耐心等待……';
          }, 3000);
        },
        error(err) {
          console.error('上传错误：', err);// 上传错误
        },
        complete(res) {
          console.log('上传完成：', res);// 上传完成
          const imageUrl = `https://qn.colorcun.com/${res.key}`;
          that.fixCancel(imageUrl)
          that.closeCamera()
        }
      };
      const subscription = observable.subscribe(observer);
    },
    // 关闭摄像头
    closeCamera () {
      if (!this.$refs['video'].srcObject) return
      let stream = this.$refs['video'].srcObject
      let tracks = stream.getTracks()
      tracks.forEach(track => {
        track.stop()
      })
      this.$refs['video'].srcObject = null
    },
    //将base64转换为文件
    dataURLtoFile(dataurl, filename) { 
      var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    closeClick () {
      this.closeCamera()
      this.$emit("su", false);
      Vue.prototype.$ELEMENT = { size: "small", zIndex: 2000 };
    },
    //下拉框选取消原因组条件
    getCancelEventType (val) {
      if (val) {
        this.$set(this.inquire, this.inquire.selectData, val);
      } else {
        this.$set(this.inquire, this.inquire.selectData, "");
      }
      this.cancelInfo = val;
    },
    //下拉框选择订金去向条件
    getDepositEventType (val) {
      if (val) {
        this.$set(this.inquire, this.inquire.depositText, val);
      } else {
        this.$set(this.inquire, this.inquire.depositText, "");
      }
      this.depositInfo = val;
    },
    fixCancel (imageUrl) {
      var nick_name = "";
      if (localStorage.getItem("nick_name")) {
        nick_name = localStorage.getItem("nick_name");
      }
      var uid = localStorage.getItem("uid");
      let params = {
        service: "Android.Customer.SetReserveStatus",
        rid: this.Detail.id,
        status: 4,
        uid: uid,
        tid: this.Detail.tid,
        cause: {
          uid: localStorage.getItem("uid"),
          desc: this.cancelInfo,
          uname: nick_name,
        },
      };
      console.log('imageUrl',imageUrl);
      if(imageUrl){//七牛云上拍摄的照片
        params.log_photo=imageUrl
      }
      order_status(params).then((res) => {
        if (res.status == 200 && res.data.data.row != 4 && this.cancelTemp == '0') {
          this.$message({
            message: '取消成功',
            type: 'success'
          });
          this.loading = false;
          eventBus.$emit('cancelRefresh');
          this.closeClick();
          if(this.depositList && this.depositList.length>0) this.SetReserveDepositFun()
        } else if (res.status == 200 && res.data.data.row != 4 && this.cancelTemp == '1') {
          this.$message({
            message: '取消成功',
            type: 'success'
          });
          this.WxSendCancelSmsFun()
          if(this.depositList && this.depositList.length>0) this.SetReserveDepositFun()
        } else {
          this.$message({
            message: '取消失败',
            type: 'error'
          });
          this.loading = false;
          this.closeClick();
        }
        this.loadingText = '取消预订中，请稍后···'
      });
    },
    // 取消订单设置定金走向
    SetReserveDepositFun(){
      let params = {
        service: "App.Reserve.SetReserveDeposit",
        rid: this.Detail.id,
        cancel:this.depositInfo
      };
      SetReserveDeposit(params).then((res) => {
      })
    },
    // 发送取消短信
    WxSendCancelSmsFun () {
      WxSendCancelSms({
        service: "App.SendSMS.WxSendCancelSms",
        rid: this.Detail.id,
        sid: localStorage.getItem("sellerid"),
      }).then(res => {
        console.log(res);
        if (res && res.data && res.data) {
          let data = res.data
          if (data.data && data.data.Code == 'OK' && data.data.Message == 'OK') {
            this.$message({
              message: '取消预订短信已发送给客户',
              type: 'success'
            });
            eventBus.$emit('cancelRefresh');
            this.closeClick();
            this.loading = false;
          } else {
            this.$message({
              message: data.msg ||  '取消预订短信发送失败！',
              type: 'error'
            });
            eventBus.$emit('cancelRefresh');
            this.closeClick();
            this.loading = false;
          }
        }
      })
    },
  },
};
</script>
<style lang='scss' scoped>
::v-deep .seleClsas .el-input__inner {
  border: none;
  box-shadow: none;
  border-radius: 44px;
  color: #000 !important;
  left: 0 !important;
  font-size: 18px;
}
.reserveCancel {
  z-index: 2;
  position: relative;
  width: 418px;
  height: 340px;
  max-height: 600px;
  border-radius: 16px;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  left: calc(50% - 200px);
  top: calc(50% - 300px);
  padding-bottom: 20px;
  .close {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid white;
    position: absolute;
    top: 26px;
    right: 26px;
    font-size: 22px;
    text-align: center;
    line-height: 22px;
    cursor: pointer;
    color: #fff;
  }
  div.popContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
  }
  .title {
    height: 68px;
    border-radius: 16px 16px 0 0;
    font-size: 30px;
    font-weight: 300;
    color: rgb(231, 241, 255);
    text-indent: 30px;
    line-height: 68px;
    background: rgba(22, 65, 89, 1);
    margin-bottom: 20px;
  }

  .reserveInfo {
    color: black;
    text-align: center;
    margin-left: 20px;
    font-size: 18px;
    line-height: 28px;
  }
  .seleClsas {
    color: black;
    border-radius: 15px;
    border: 1px solid rgba(144, 151, 159, 1);
    box-sizing: border-box;
    border-radius: 8px;
    width: 75%;
    margin-bottom: 20px;
  }
  .news{
    width: 75% !important;
    margin-bottom: 20px;
    border-radius: 7px;
    padding: 10px;
    font-size: 14px;
  }
  .tsText {
    color: black;
    text-align: center;
    font-size: 18px;
  }
  .backBox {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .back {
    width: 132px;
    height: 40px;
    border-radius: 8px;
    background: rgba(22, 65, 89, 1);
    color: white;
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    margin-right: 10px;
    cursor: pointer;
  }

  .send {
    width: 132px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid;
    border: 1px solid #164159;
    background: #fff;
    color: #164159;
    text-align: center;
    font-size: 18px;
    line-height: 40px;
    cursor: pointer;
    padding: 0;
  }
}
canvas{
  display: none;
}
video{
  display: none;
}
</style>
