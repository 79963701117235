import Vue from 'vue'
import Vuex from 'vuex'
import { GetSellerVersion, user_login } from "@/api/api"
import $router from "@/router/index"
import { Message } from 'element-ui'
import { getDiffTime } from '../../src/assets/js/utils'
import websocket from './modules/websocket';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userinfo: {},
    info:{},
    getSeller: {//获取店铺过期时间
      service: "App.Seller.GetSellerById",
      seller_id: '',
      sellerid:'',
    },
    callPhone:{//获取第二个来电信息
      num:'',
      time:'',
      phone:'',
    },
    openRecordObj:{//录音配置
      openRecord:'',
      listings:'',
    },
    reserveInfo:{},//变更数据
  },
  getters: {
  },
  mutations: {
    // 修改用户信息
    setuser (state, params) {
      state.userinfo = params
    },
    // 获取来电信息
    getInfo (state, params) {
      state.info = params
    },
    // 获取第二个来电信息
    getCallPhone (state, params) {
      state.callPhone = params
    },
    // 录音配置
    getOpenRecordObj (state, params) {
      console.log('录音配置',{...params});
      state.openRecordObj = params
    },
    // 变更预订页面，有来电，在来电弹框点击变更预订，重置预订数据
    getReserveInfoObj (state, params) {
      state.reserveInfo = params
    },
  },
  actions: {
    //登录信息
    setlogin ({ commit, state }, params) {
      return new Promise((resolve, reject) => {
      // login: {
      //   service: "Android.User.AppUserLogin",
      //   seller_code: "",
      //   alias: "ssahdahgdafgahjkhakjgh",
      //   account: "",
      //   password: "",
      // },
      if (params.login.seller_code == "") {
        Message.error('请输入店铺号');
        return
      }
      if (params.login.account == "") {
        Message.error('请输入用户号');
        return
      }
      if (params.login.password == "") {
        Message.error('请输入密码');
        return
      }
      user_login(params.login).then((res) => {
        if (res.data.ret == 200) {
          if (params.chenbox1) {
            localStorage.setItem("seller_code", params.login.seller_code)
            localStorage.setItem("account", params.login.account)
          }
          if (params.chenbox1 && params.chenbox2) {
            localStorage.setItem("password", params.login.password)
          }
          commit("setuser", res.data.data[0].user)
          localStorage.setItem("account", params.login.account)
          localStorage.setItem("sellerid", res.data.data[0].user.sellerid)
          localStorage.setItem("seller", JSON.stringify(res.data.data[0].seller))
          localStorage.setItem("uid", res.data.data[0].user.id)
          localStorage.setItem("nick_name", res.data.data[0].user.nick_name)
          localStorage.setItem("role_name", res.data.data[0].user.role_name)
          state.getSeller.sellerid=res.data.data[0].user.sellerid
          // 获取商家的版本信息
          GetSellerVersion({
            service:'App.SellerVersion.GetSellerVersion',
            sellerid:res.data.data[0].user.sellerid,
          }).then((res) => {
            let data=res.data.data;
            let dates = Date.parse(new Date()) / 1000// 现在年月日时分秒的时间戳
            if(data && data.length>0){
              data.forEach(item => {
                let dateStr = new Date(item.rdate * 1000);
                dateStr.setHours(0, 0, 0, 0); 
                item.rdateDate = dateStr.getTime() / 1000;// 启用时间的年月日加0时0分0秒
              });
              data = data.filter(item => item.rdateDate < dates);//去除还没到启用时间的数据
            }
            if(data && data.length>0){
              data.sort((a, b) => b.cdate*1 - a.cdate*1);
              // 获取店铺当前版本  （cdate最大最后一个充入的 && 当前时间有效 && 当前版本）
              let obj = data.find(item => item.vtype == 1 || item.vtype == 2 || item.vtype == 4);
              console.log('当前版本2',{...obj});
              localStorage.setItem("sellEdate", obj.edate)//过期弹框
              let residue = obj.edate - dates//剩余天数的时间戳
              let residueDate = getDiffTime(obj.edate, dates)//剩余天数的时间戳
              console.log('过期的时间戳---', obj.edate, '现在年月日时分秒的时间戳---', dates, '剩余天数时间戳---', residue, '剩余天数数组---', residueDate);
              if(obj.vid == '14'){
                resolve('-1');//极速版
              }else{
                // 剩余时间戳大于0
                resolve(residue);
              }
            }else{
              resolve(1);
              localStorage.setItem("sellEdate", 1)//过期弹框
            }
          })
        } else {
          Message.error('登陆失败！账户或密码错误');
        }
      })
    })
    }
  },
  modules: {
    websocket
  }
})
